'use client';
import { LinkButton } from 'components/link-button';
import { scrollTo } from '@/helpers/scrollTo';
import { Button } from '@/components/commonComponents/button/button';
import { StrapiButton } from '@/types/misc';

import styles from './styles.module.scss';

export const HeroButtons = ({ buttons }: { buttons: StrapiButton[] }) => {
  const primaryButton = buttons &&
    (buttons[0]?.href ||
      buttons[0]?.pageLink?.slug ||
      buttons[0]?.scrollTo) && {
      ...(buttons[0]?.href && { href: buttons[0]?.href }),
      ...(buttons[0]?.pageLink && { href: buttons[0]?.pageLink?.slug }),
      ...(buttons[0]?.scrollTo && {
        onClick: () =>
          scrollTo((buttons as StrapiButton[])[0].scrollTo as string, 0),
      }),
      ...(buttons[0]?.surface && { surface: buttons[0].surface }),
      children: buttons[0]?.copy || 'Meer info',
    };

  return (
    <div className={styles.buttons}>
      {primaryButton && (
        <Button style="primary" {...primaryButton}>
          {primaryButton.children}
        </Button>
      )}
      {(buttons[1]?.href || buttons[1]?.pageLink?.slug) && (
        <LinkButton
          href={buttons[1]?.href || buttons[1]?.pageLink?.slug}
          className={styles.underlineButton}
          label={buttons[1]?.copy || 'Meer info'}
        />
      )}
    </div>
  );
};
