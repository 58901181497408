import classNames from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';

interface SkeletonProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
  loading?: boolean;
}

export const Skeleton = ({
  className,
  children = null,
  loading = false,
  ...props
}: SkeletonProps) => {
  if (!loading) {
    return children;
  }

  return (
    <div
      className={classNames(className, {
        ['animate-pulse rounded-md bg-neutral-100 flex [&>*]:invisible']:
          loading,
      })}
      {...props}
    >
      {children}
    </div>
  );
};
