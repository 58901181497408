'use client';
import { useState } from 'react';
import { Skeleton } from '@/components/skeleton/skeleton';
import Image from 'next/image';
import classNames from 'classnames';

import { calculateAspectRatio } from './helpers/calculateAspectRatio';

export interface MultiImageItemProps {
  image: {
    name: string;
    alternativeText: string;
    url: string;
    width: number;
    height: number;
  };
}

export const MultiImageItem = ({
  image: { name, url, width, height, alternativeText },
}: MultiImageItemProps) => {
  const [loading, setLoading] = useState(true);

  const dismissLoading = () => {
    setLoading(false);
  };

  const aspectRatio = calculateAspectRatio(width, height);

  return (
    <>
      {loading ? (
        <Skeleton
          key={`${name}-skeleton`}
          loading
          style={{ aspectRatio }}
          className="h-auto w-full object-contain"
        />
      ) : null}
      <div
        key={name}
        className={classNames(
          { ['invisible absolute left-0 bottom-0']: loading },
          `h-fit w-full`
        )}
      >
        <Image
          fill
          alt={alternativeText || name}
          sizes="(max-width: 639px) 100vw,
        (max-width: 1279px) 50vw,
        33vw"
          className="object-contain !h-fit !relative"
          src={url}
          onLoad={dismissLoading}
        />
      </div>
    </>
  );
};
